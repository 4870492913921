



















































































































import { Vue, Component } from 'vue-property-decorator'
import {
  getAccounts,
  TradePair,
  FutureSpotOrder, getSpotSummary,
} from '@/clients/cpinblocks'
import { BigNumber } from 'bignumber.js'
import { Account } from '@/models/Account'
import { Currency, TokenType } from '@/types'
import AddSpotOrder from '@/components/AddSpotOrder.vue'
import AddCheckout from '@/components/AddCheckout.vue'
import { formatFixedDecimalsNoUselessZero } from '@/utils'

@Component({
	components: {
		AddCheckout,
		AddSpotOrder,
	},
})
export default class Trading extends Vue {
	accounts: Account[] = []
	pairs: Array<Record<string, unknown>> = []
	showDialog = false
	spotPairs: TradePair[] | null = null;
	type = ''
	max = new BigNumber('0')
	productPrecision = 0
	unitPricePrecision = 0
	productCurrency = ''
	productType = ''
	showCheckout = false
	unitPriceCurrency = ''
	unitPriceType = ''
	currentPrice = new BigNumber('0')
	minPrice: BigNumber | null = null
	minUnitPrice: BigNumber | null = null
	maxUnitPrice: BigNumber | null = null
	headers = [
		{
			align: 'left',
			sortable: false,
			value: 'pair',
			text: 'Pair',
			width: '15%',
		},
		{
			align: 'center',
			sortable: false,
			value: 'price',
			text: 'Price',
			width: '15%',
		},
		{
			align: 'center',
			sortable: false,
			value: 'trend',
			text: 'Trend',
			width: '40%',
		},
		{
			align: 'center',
			sortable: false,
			value: 'actions',
			text: 'Actions',
			width: '30%',
		},
	]

	loading = true

	private onTrade (item: any): void {
		let pair = item.productCurrency
		if (item.productType !== 'MAIN') {
			pair += '-' + item.productType
		}
		pair += '_' + item.unitPriceCurrency
		if (item.unitPriceType !== 'MAIN') {
			pair += '-' + item.unitPriceType
		}

		this.$router.push({
			name: this.$keycloak.authenticated ? 'SpotPair' : 'PublicSpotPair',
			params: {
				pair: pair,
			},
		})
	}

	private getBalance (currency: Currency, type: TokenType): BigNumber | null {
		for (const a of this.accounts) {
			if (a.currency === currency && a.type === type) {
				return a.balance
			}
		}
		return null
	}

	get hasIBXE (): boolean {
		const balance = this.getBalance('IBXE', 'MAIN')
		return balance !== null && balance.gt(0)
	}

	private isBuyVisible (item: any): boolean {
    return this.$keycloak.authenticated === true && this.$store.getters.isAtLeastKYCLevel1
	}

	private isSellDisabled (item: any): boolean {
		const balance = this.getBalance(item.productCurrency, item.productType)
		return balance === null || balance.eq(0)
	}

	private isSellVisible (item: any): boolean {
    return this.$keycloak.authenticated === true && this.$store.getters.isAtLeastKYCLevel1
        && item.checkout === 'DISABLED'
	}

	private async onBuy (item: any): Promise<void> {
		this.accounts = await getAccounts(this.$store.state.jwt, false)
		this.type = 'BUY'
		this.productPrecision = item.productPrecision
		this.unitPricePrecision = item.unitPricePrecision
		this.productCurrency = item.productCurrency
		this.productType = item.productType
		this.unitPriceCurrency = item.unitPriceCurrency
		this.unitPriceType = item.unitPriceType
		this.currentPrice = item.price
		this.max = this.getBalance(item.unitPriceCurrency, item.unitPriceType) ?? new BigNumber('0')
	  this.minPrice = item.minPrice
	  this.minUnitPrice = item.minUnitPrice
	  this.maxUnitPrice = item.maxUnitPrice
	  this.showDialog = true
	}

	private async onSell (item: any): Promise<void> {
		this.accounts = await getAccounts(this.$store.state.jwt, false)
		this.type = 'SELL'
		this.productPrecision = item.productPrecision
		this.unitPricePrecision = item.unitPricePrecision
		this.productCurrency = item.productCurrency
		this.productType = item.productType
		this.unitPriceCurrency = item.unitPriceCurrency
		this.unitPriceType = item.unitPriceType
		this.currentPrice = item.price
		this.max = this.getBalance(item.productCurrency, item.productType) ?? new BigNumber('0')
	  this.minPrice = item.minPrice
	  this.minUnitPrice = item.minUnitPrice
	  this.maxUnitPrice = item.maxUnitPrice
	  this.showDialog = true
	}

	private async openCheckoutIBXE (item: any): Promise<void> {
		this.productCurrency = item.productCurrency
		this.showCheckout = true
	}

	private async onOrderPassed (): Promise<void> {
		this.showDialog = false
		await this.refresh()
	}

	async refresh (): Promise<void> {
		this.spotPairs = await getSpotSummary(this.$store.state.jwt)
		if (this.$keycloak.authenticated) {
			this.accounts = await getAccounts(this.$store.state.jwt, false)
		}
		this.pairs = []
		if (this.spotPairs) {
			for (const p1 of this.spotPairs) {
				if (p1.productType === 'MAIN' || p1.productType === 'OPTION') {
					this.pairs.push({
						pair: p1.productCurrency + (p1.productType !== 'MAIN' ? ' (' + p1.productType + ')' : '') + ' / ' + p1.unitPriceCurrency + (p1.unitPriceType !== 'MAIN' ? ' (' + p1.unitPriceType + ')' : ''),
						price: p1.price ? formatFixedDecimalsNoUselessZero(this.$i18n, new BigNumber(p1.price), p1.unitPricePrecision) : 'N/A',
						trend: p1.trend ? new BigNumber(p1.trend) : new BigNumber(0),
						trendInterval: p1.trendInterval,
						productCurrency: p1.productCurrency,
						productType: p1.productType,
						productPrecision: p1.productPrecision,
						unitPriceCurrency: p1.unitPriceCurrency,
						unitPriceType: p1.unitPriceType,
						unitPricePrecision: p1.unitPricePrecision,
						minPrice: p1.minPrice,
						minUnitPrice: p1.minUnitPrice,
						maxUnitPrice: p1.maxUnitPrice,
            checkout: p1.checkout,
					})
				}
			}
		}

		this.pairs.sort()
		this.loading = false
	}

	handleRowClick (item: FutureSpotOrder): void {
		this.onTrade(item)
	}

	async onOrderCreated (): Promise<void> {
		this.showDialog = false
	}

	async onOrderFailed (): Promise<void> {
		this.showDialog = false
		await this.refresh()
	}

	private async mounted (): Promise<void> {
		await this.refresh()
	}
}
