



















































































































































import { createTradeOrder, getAPIConfiguration } from '@/clients/cpinblocks'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import ModalNotification from '@/components/ModalNotification.vue'
import { alertRawError, convertValueToBigNumber, formatFixedDecimals } from '@/utils'
import { BigNumber } from 'bignumber.js'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationRules } from '@/types'
import { TranslateResult } from 'vue-i18n'
import { Conf } from '@/models/Conf'

@Component({
	components: {
		CurrencyInput,
		Code2FADialog,
		ModalNotification,
	},
})

export default class AddSpotOrder extends Vue {
	@Prop() currentPrice?: BigNumber
	@Prop() minPrice?: BigNumber
	// TODO: use the precision to set the default minimum unit price => new BigNumber('10').pow(-1 * this.unitPricePrecision)
	@Prop({ default: new BigNumber(0.0001) }) minUnitPrice?: BigNumber
	@Prop() maxUnitPrice?: BigNumber
	@Prop() max!: BigNumber
	@Prop() productCurrency!: string
	@Prop() productPrecision!: number
	@Prop() productType!: string
	@Prop() showDialog!: BigNumber
	// buy or sell
	@Prop() type!: string
	@Prop() unitPriceCurrency!: string
	@Prop() unitPricePrecision!: number
	@Prop() unitPriceType!: string

	private conf: Conf | null = null
	private creating = false
	private currentFee = new BigNumber(0)
	private isNotificationVisible = false
	private isValid = false
	private loading = true
	private notificationText: TranslateResult = ''
	private quantity = new BigNumber('0')
	private show2FADialog = false
	private unitPrice = new BigNumber('0')

	get totalPrice (): BigNumber {
		const netPrice = this.unitPrice.multipliedBy(this.quantity)
		if (this.type === 'BUY') {
			return netPrice.plus(this.currentFee)
		} else if (this.type === 'SELL') {
			return netPrice.minus(this.currentFee)
		}
		return new BigNumber(NaN)
	}

	get totalPricePrecision (): number {
		return this.unitPricePrecision * this.productPrecision + this.feePrecision
	}

	// TODO: remove magic number
	get feePrecision (): number {
		return 4
	}

	get currencyDisplayed (): string {
		return this.unitPriceCurrency + (this.unitPriceType !== 'MAIN' ? ' (' + this.unitPriceType + ')' : '')
	}

	get productDisplayed (): string {
		return this.productCurrency + (this.productType !== 'MAIN' ? ' (' + this.productType + ')' : '')
	}

	private get rules (): ValidationRules {
		const result: ValidationRules = {
			required: [
				(v?: string) => !!v || this.$t('rule.requiredField'),
			],
			unitPrice: [
				(v?: string) => !!v || this.$t('rule.requiredField'),
				(v: string) => !convertValueToBigNumber(v).isNaN() || this.$t('rule.requiredField'),
				(v: string) => !this.minUnitPrice || convertValueToBigNumber(v).isGreaterThanOrEqualTo(this.minUnitPrice) || this.$t('addOffer.rule.minUnitPrice', {
					currency: this.unitPriceCurrency,
					minPrice: this.minUnitPrice,
				}),
				(v: string) => !this.maxUnitPrice || convertValueToBigNumber(v).isLessThanOrEqualTo(this.maxUnitPrice) || this.$t('addOffer.rule.maxUnitPrice', {
					currency: this.unitPriceCurrency,
					maxPrice: this.maxUnitPrice,
				}),
			],
		}
		if (this.type === 'BUY') {
			result.quantitySold = [
				(v: string) => convertValueToBigNumber(v).gt(0) || this.$t('spotOrder.minQuantity', { min: 0 }),
			]
			result.total = [
				(v?: string) => !!v || this.$t('rule.requiredField'),
				(v: string) => !convertValueToBigNumber(v).isNaN() || this.$t('rule.requiredField'),
				(v: string) => {
					return !this.minPrice || convertValueToBigNumber(v).isGreaterThanOrEqualTo(this.minPrice) || this.$t('spotOrder.minPriceThreshold', {
						currency: this.unitPriceCurrency,
						min: formatFixedDecimals(this.$i18n, this.minPrice, this.productPrecision),
					})
				},
				(v: string) => {
					return convertValueToBigNumber(v).isLessThanOrEqualTo(this.max) || this.$t('spotOrder.insufficientFundsPrice', {
						max: formatFixedDecimals(this.$i18n, this.max, this.productPrecision),
					})
				},
			]
		} else if (this.type === 'SELL') {
			result.quantitySold = [
				(v?: string) => !!v || this.$t('rule.requiredField'),
				(v: string) => convertValueToBigNumber(v).gt(0) || this.$t('spotOrder.minQuantity', { min: 0 }),
				(v: string) => !convertValueToBigNumber(v).isNaN() || this.$t('rule.requiredField'),
				(v: string) => {
					return convertValueToBigNumber(v).isLessThanOrEqualTo(this.max) || this.$t('spotOrder.insufficientFundsQuantity', {
						max: formatFixedDecimals(this.$i18n, this.max, this.productPrecision),
					})
				},
			]
			result.total = [
				(v: string) => {
					return !this.minPrice || convertValueToBigNumber(v).isGreaterThanOrEqualTo(this.minPrice) || this.$t('spotOrder.minPriceThreshold', {
						currency: this.unitPriceCurrency,
						min: formatFixedDecimals(this.$i18n, this.minPrice, this.productPrecision),
					})
				},
			]
		}
		return result
	}

	async created (): Promise<void> {
		this.conf = await getAPIConfiguration()
		await this.reset()
		this.loading = false
	}

	async cancel (): Promise<void> {
		await this.reset()
		this.$emit('cancel')
	}

	async open2FADialog (): Promise<void> {
		this.creating = true
		this.show2FADialog = true
	}

	onNotificationClose (): void {
		this.isNotificationVisible = false
		this.$emit('success')
	}

	async create (object: any): Promise<void> {
		const code2FA = object.code
		this.show2FADialog = false
		this.creating = true

		try {
			const result = await createTradeOrder(this.$store.state.jwt, code2FA, this.type, this.quantity, this.productCurrency, this.productType, this.unitPrice, this.unitPriceCurrency, this.unitPriceType)

			if (result) {
				this.isNotificationVisible = true
				const productValue = new BigNumber(result.product.value)
				if (productValue.isEqualTo(0)) {
					this.notificationText = this.$t('addOrder.notification.executed')
				} else if (this.quantity.isEqualTo(productValue)) {
					this.notificationText = this.$t('addOrder.notification.notExecuted')
				} else if (productValue.isGreaterThan(0) && productValue.isLessThan(this.quantity)) {
					const orderType = this.type === 'BUY' ? this.$t('addOrder.typeBought') : this.$t('addOrder.typeSold')
					this.notificationText = this.$t('addOrder.notification.partiallyExecuted', {
						tokenAmount: this.quantity.minus(productValue),
						token: result.product.currency,
						orderType: orderType,
						totalAmountCurrency: result.unitPrice.value,
						currency: result.unitPrice.currency,
					})
				}
				this.creating = false
				this.$emit('onOrderCreated')
			}
		} catch (error: any) {
			alertRawError(error.message)
			this.creating = false
			this.$emit('fail')
		}
	}

	async reset (): Promise<void> {
		if (this.currentPrice && !(new BigNumber(this.currentPrice)).isNaN()) {
			this.unitPrice = new BigNumber(this.currentPrice)
		}
		this.quantity = new BigNumber('0')
	}

	validateForm (): void {
		(this.$refs.form as Vue & { validate: () => boolean }).validate()
	}

	private async updateFee (): Promise<void> {
		// TODO: uncomment when API is ready (after testing of course)
		/* if (this.currentPrice) {
			this.currentFee = await getSpotFee(this.$store.state.jwt, this.type, this.productCurrency, this.productType,
				this.unitPriceCurrency, this.unitPriceType, this.quantity)
		} */
	}
}
